import { Route } from "react-router-dom";
import { TimeZoneList } from "../consts/tz-list";
import { HomePage } from "./homepage";
import { getLocaleKeys } from "../i18n/locales";

export const HomePageRoutes = (): JSX.Element[] => {
    const tzList: string[] = TimeZoneList.map(tz => tz.abbrev);
    const homePageRoutes: JSX.Element[] = [];
    tzList.forEach(tzFrom => {
        tzList.forEach(tzTo => {
            if (tzFrom !== tzTo) {
                getLocaleKeys().forEach(localeKey => {
                        const path = `${localeKey}/timezone-converter/${tzFrom}-to-${tzTo}`;
                        homePageRoutes.push(<Route path={path} key={path} element={<HomePage language={localeKey} fromTimeZone={tzFrom} toTimeZone={tzTo} />} />);
                });
                const defaultPath = `timezone-converter/${tzFrom}-to-${tzTo}`;
                homePageRoutes.push(<Route path={defaultPath} key={defaultPath} element={<HomePage language={'en'} fromTimeZone={tzFrom} toTimeZone={tzTo} />} />);
            }
        });
    });

    getLocaleKeys().forEach(key => {
        homePageRoutes.push(<Route path={`${key}`} key={`${key}`} element={<HomePage language={key} />} ></Route>);
    });

    homePageRoutes.push(<Route path="/" key="root" element={<HomePage language='en' />} ></Route>);

    // add concrete time
    const times = [
        "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am","12am",
        "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm","12pm",
    ];
    getLocaleKeys().forEach(localeKey => {
        // ist to est
        times.forEach(time => {
            const path = `${localeKey}/timezone-converter/${time}-ist-to-est`;
            homePageRoutes.push(
                <Route 
                    path={path} 
                    key={path} 
                    element={<HomePage language={localeKey} fromTimeZone={"ist"} toTimeZone={"est"} fromTime={time} />}
                />
            );
        });
    });

    getLocaleKeys().forEach(localeKey => {
        // est to ist
        times.forEach(time => {
            const path = `${localeKey}/timezone-converter/${time}-est-to-ist`;
            homePageRoutes.push(
                <Route
                    path={path}
                    key={path}
                    element={<HomePage language={localeKey} fromTimeZone={"est"} toTimeZone={"ist"} fromTime={time} />}
                />
            );
        });
    });

    return homePageRoutes;
}