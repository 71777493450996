import { CSSProperties } from 'react';
export const ControlIconsWidth = 13;
export const HourWidth = 28;
export const HourHeight = 40;
export const RowGap = 4;
export const CityInfoWidth = 150;
export const CurrentTimeInfoWidth = 120;
export const CurrentTimeInfoMarginRight = 50;
export const SelectAreaLeft = ControlIconsWidth + CityInfoWidth + CurrentTimeInfoWidth + CurrentTimeInfoMarginRight;


export const ulStyle: CSSProperties = {
  display: 'flex',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  cursor: 'default',
};

export const getTextColor = (hour: number): string => {
  if (hour >= 8 && hour <= 17) {
    return '#8ba3c9';
  } else if (hour === 6 || hour === 7 || (hour >= 18 && hour <= 21)) {
    return '#8ba3c9';
  } else {
    return '#edfbff';
  }
}

export const ampmStyle: CSSProperties = {
  fontSize: 10,
};

export const getLiStyle = (hour: number): CSSProperties => {
  let baseStyle: CSSProperties = {
    width: HourWidth,
    height: HourHeight,
    boxSizing: 'border-box',
    borderTop: '1px solid #8ba3c9',
    borderBottom: '1px solid #8ba3c9',
    textAlign: 'center',
    paddingTop: 5,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  };
  if (hour >= 8 && hour <= 17) {
    baseStyle = { ...baseStyle, backgroundColor: '#fffff3' };
  } else if (hour === 6 || hour === 7 || (hour >= 18 && hour <= 21)) {
    baseStyle = { ...baseStyle, backgroundColor: '#edfbff' };
  } else {
    baseStyle = { ...baseStyle, backgroundColor: '#95b3d7' };
  }

  if (hour === 0) {
    baseStyle = {
      ...baseStyle,
      borderRadius: '8px 0 0 8px',
      WebkitBorderRadius: '8px 0 0 8px',
    };
  }
  if (hour === 23) {
    baseStyle = {
      ...baseStyle,
      borderRadius: '0 8px 8px 0',
      WebkitBorderRadius: '0 8px 8px 0',
      width: 27,
      marginRight: 1,
    };
  }
  return { ...baseStyle, color: getTextColor(hour)};
};
