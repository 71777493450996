import * as React from 'react';
import { TDExtendedProps, TimeAndDateInfoProps } from '../interface';

export const weekdayFormatter = (locale: string, timezone: string): Intl.DateTimeFormat => new Intl.DateTimeFormat(locale, {
  timeZone: timezone,
  weekday: 'short',
});

export const timeFormatter = (locale: string, timezone: string) => new Intl.DateTimeFormat(locale, {
  timeZone: timezone,
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
});

export const dateFormatter = (locale: string, timezone: string) => new Intl.DateTimeFormat(locale, {
  timeZone: timezone,
  month: 'short',
  day: 'numeric',
});

export const dayFormatter = (locale: string, timezone: string) => new Intl.DateTimeFormat(locale, {
  timeZone: timezone,
  day: 'numeric',
});

export const monthFormatter = (locale: string, timezone: string) => new Intl.DateTimeFormat(locale, {
  timeZone: timezone,
  month: 'short',
});

export const hourCoverter = (locale: string, timezone: string) => new Intl.DateTimeFormat(locale, {
  timeZone: timezone,
  hour: 'numeric',
  hour12: false,
});

// const timeZoneConverter = (locale: string, timezone: string) => new Intl.DateTimeFormat(locale, {
//   timeZone: timezone,
//   year: 'numeric',
//   month: 'numeric',
//   day: 'numeric',
// });

export const getCurrentHour = (locale: string, timezone: string): string => {
  const currDate = new Date();
  const hour = hourCoverter(locale, timezone).format(currDate);
  return hour;
}

export const makeTimeZoneInfo = (locale: string, defaultTimezone: string, timezone: string, offset: number): TimeAndDateInfoProps => {
  const currDate = new Date();
  const hour = hourCoverter(locale, defaultTimezone).format(currDate);
  const hourOffset = offset/2 - Number(hour);
  currDate.setHours(currDate.getHours() + hourOffset, 30*(offset%2), 0);

  const result = {
    weekday: weekdayFormatter(locale, timezone).format(currDate),
    time: timeFormatter(locale, timezone).format(currDate),
    date: dateFormatter(locale, timezone).format(currDate),
  };
  return result;
}

export const useMonthDayBasedOnOffsetHour = (locale: string, timezone: string, offsetHour: number): {month: string, day: string} => {
  const currDate = new Date();
  // set offset hour to currDate
  const date = new Date(currDate.getTime() + offsetHour * 60 * 60 * 1000);

  const result = {
    month: monthFormatter(locale, timezone).format(date),
    day: dayFormatter(locale, timezone).format(date),
  };
  return result;
}

export const useCurrentDateTime = (locale: string, timezone: string): TDExtendedProps => {
  const [currentTime, setCurrentTime] = React.useState(new Date());

  React.useEffect(() => {
    // set interval to update current time
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  return {
    weekday: weekdayFormatter(locale, timezone).format(currentTime),
    time: timeFormatter(locale, timezone).format(currentTime),
    date: dateFormatter(locale, timezone).format(currentTime),
    month: monthFormatter(locale, timezone).format(currentTime),
    day: dayFormatter(locale, timezone).format(currentTime),
  };
}