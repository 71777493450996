import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../i18n/i18n';
import { getLocaleKeys } from '../i18n/locales';
import { baseURL } from '../consts/const';

export const HomePageHead = (props: { fromTimeZone: string, toTimeZone: string, language: string }) => {
    const { t, i18n } = useTranslation();
    const { fromTimeZone, toTimeZone } = props;
    const fromTimeZoneDisplayName = t(`tz.${fromTimeZone}.displayName`);
    const toTimeZoneDisplayName = t(`tz.${toTimeZone}.displayName`);

    return (
        <Helmet htmlAttributes={{ lang: props.language }}>
            <meta charSet="utf-8" />
            <title>{t('headHomepage.title', { fromTimeZone: fromTimeZone, toTimeZone: toTimeZone })}</title>
            <meta name="description" content={t('headHomepage.description', {
                fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                toTimeZoneDisplayName: toTimeZoneDisplayName,
            })} />
            <meta name="keywords" content={t('headHomepage.keywords', {
                fromTimeZone: fromTimeZone,
                toTimeZone: toTimeZone,
                fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                toTimeZoneDisplayName: toTimeZoneDisplayName,
            })} />
            {
                getLocaleKeys().map(lang => {
                    const url = `${baseURL}/${lang}/timezone-converter/${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`;
                    return <link rel="alternate" hrefLang={lang}
                        href={url}
                        key={url}
                    />;
                })
            }
            {

                <link rel="alternate" hrefLang="x-default" href={`${baseURL}/en/timezone-converter/${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`} key='xdefault' />
            }
            {
                <link rel="canonical" href={`${baseURL}/en/timezone-converter/${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`} key='canonical' />
            }
        </Helmet>
    );
}