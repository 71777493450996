import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';
import { HomePageHead } from './homepage-head';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { TimeZoneTableWithContext } from '../timezone-table';

type TimeZoneAbbrev = string;

interface HomePageProps {
  language?: string;
  fromTimeZone?: TimeZoneAbbrev;
  fromTime?: string;
  toTimeZone?: TimeZoneAbbrev;
}

export const HomePage = (props: HomePageProps) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
      i18n.changeLanguage(props.language || 'en');
    }, []);
  
  
    return (
      <div className="App">
          <HomePageHead fromTimeZone={props.fromTimeZone || 'EST'} toTimeZone={props.toTimeZone || 'IST'} language={props.language || 'en'} />
          <Header />
          <div className='container'>
              {
                props.fromTime
                ?
                  <h1>{`${props.fromTime} ${props.fromTimeZone || 'EST'} to ${props.toTimeZone || 'IST'}`}</h1>
                :
                  <h1>{`${props.fromTimeZone || 'EST'} to ${props.toTimeZone || 'IST'}`}</h1>
              }
              {
                props.fromTime
                ?
                  <h2>{`${props.fromTime} ${props.fromTimeZone || 'EST'} to ${props.toTimeZone || 'IST'} time zone converter`}</h2>
                :
                  <h2>{`${props.fromTimeZone || 'EST'} to ${props.toTimeZone || 'IST'} time zone converter`}</h2>
              }
              <h3>{`Convert ${props.fromTimeZone || 'EST'} to ${props.toTimeZone || 'IST'}`}</h3>
              <div>{t(`tz.${(props.fromTimeZone || 'EST').toUpperCase()}.description`)}</div>
              <div>{t(`tz.${(props.toTimeZone || 'EST').toUpperCase()}.description`)}</div>
              <TimeZoneTableWithContext fromTZ={props.fromTimeZone} toTZ={props.toTimeZone} fromTime={props.fromTime} />
          </div>
          <Footer />
      </div>
    );
}